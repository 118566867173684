@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,500;1,100;1,300;1,500&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

* {
  font-family: "Inter", sans-serif;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

*::-webkit-scrollbar {
  display: none;
}

/* html {
  background-color: #e1eaf6;
} */

/* html.dark {
  background-color: rgba(32, 32, 32, 1);
}

body {
  min-height: 100vh;
} */

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: auto;
}

.spinner div {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: spinner-animation 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  animation-delay: -0.16s;
}

@keyframes spinner-animation {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
